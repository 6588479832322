import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));

const Section = React.lazy(() => import("../BlogPage/Section"));
const Blog = React.lazy(() => import("../../component/Blog"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: '', navheading: 'Home' },
        { id: 2, idnm: 'about', navheading: 'About' },
        { id: 3, idnm: 'services', navheading: 'Services' },
        { id: 4, idnm: 'careers', navheading: 'Careers' },
        { id: 5, idnm: 'contact', navheading: 'Contact' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "navbar-light navbar-custom sticky sticky-dark",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky navbar-light navbar-custom sticky sticky-dark", imglight: false });
    } else {
      this.setState({ navClass: "navbar-light navbar-custom sticky sticky-dark", imglight: true });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Importing Section */}
          <Section />

          {/* Importing Blog */}
          <Blog />

        {/* Importing Footer */}
          <Footer />

        </Suspense>
      </React.Fragment>
    );
  }
}
export default BlogPage;
