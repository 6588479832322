import Layout5 from '../pages/Layout5/Layout5';

// blogs
import Blog1 from "../pages/Blog1/Blog1";
import Blog2 from "../pages/Blog2/Blog2";

// Pages
import CareerPage from "../pages/CareerPage/CareerPage";
import ServicePage from "../pages/ServicePage/ServicePage";
import BlogPage from "../pages/BlogPage/BlogPage";
import ContactPage from "../pages/ContactPage/ContactPage";
import AboutPage from "../pages/AboutPage/AboutPage";


const routes = [
  { path: "/", component: <Layout5 /> },
  { path: "/blog/1", component: <Blog1 /> },
  { path: "/blog/2", component: <Blog2 /> },
  { path: "/careers", component: <CareerPage /> },
  { path: "/services", component: <ServicePage /> },
  { path: "/blogs", component: <BlogPage /> },
  { path: "/contact", component: <ContactPage /> },
  { path: "/about", component: <AboutPage /> },
];

export default routes;
